section {
  padding: rem(40) 0;

  &.dashboard {
    .no-min {
      min-height: auto !important;
    }
    .panel {
      height: 100%;
      border-radius: 15px;
      border: 2px solid rgba($primary, 0.4);
      background: #141112;
      padding: 25px;
      position: relative;

      .xp-overview {
        position: absolute;
        top: 12px;
        right: 13px;

        button {
          color: $secondary;

          svg {
            fill: $primary;
            transition: all 0.5s;
          }

          &:hover svg {
            opacity: 0.9;
            fill: $secondary;
          }
        }
      }

      .main-counter {
        width: 310px;
        height: 310px;
        margin: 0 auto;
        border: 3px solid #fff;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        text-align: center;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 332px;
        border: 2px solid var(--FFA51D, #ffa51d);
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(255, 165, 29, 0.15) 0%,
          rgba(20, 17, 18, 0.15) 100%
        );
        box-shadow: 0px 4px 17.3px 0px #ffa51d;

        h3 {
          font-size: 50px;
          font-family: 'Satoshi-Black';
          margin: 0;
        }

        p {
          color: rgba($primary, 0.5);
        }
      }

      .counters {
        margin: 10px 0;

        .counter {
          border: 1px solid rgba($primary, 0.4);
          margin: 10px auto;
          border-radius: 100%;
          width: 90px;
          height: 90px;
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;
          justify-content: center;

          h4 {
            margin: 0;
            font-size: 18px;
            color: $secondary;
            font-family: 'Satoshi-Medium';
          }

          p {
            font-size: 12px;
          }
        }
      }

      .maturation {
        margin-top: 20px;

        h4 {
          margin: 0;
        }

        p {
          opacity: 0.5;
        }

        .countdown {
          font-size: 22px;
          color: $secondary;

          span {
            color: $primary;
          }
        }
      }

      .sub-level h3 {
        margin: 0;

        span {
          color: $secondary;
        }
      }

      .owl-stage-outer {
        margin-bottom: 25px;
      }

      .cards-block {
        .card {
          background: transparent;
          border: 1px solid rgba($primary, 0.4);
          border-radius: 20px;
          overflow: hidden;
          text-align: center;

          &.locked:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: #000;
            opacity: 0.5;
            z-index: 99;
          }

          .dis {
            padding: 10px;

            h4 {
              font-size: 15px;
              margin: 0;

              span {
                color: $secondary;
              }
            }

            p {
              font-size: 12px;
              margin-top: 5px;
            }
          }

          .action {
            button {
              width: 100%;
              border-radius: 0;
              border: none;
              border-top: 1px solid rgba($primary, 0.4);
              font-size: 15px;

              .icon {
                width: 17px;
                height: 17px;
              }

              &:hover {
                background: transparent;

                .icon {
                  filter: none;
                }
              }

              &:has(.icon-claim) {
                background: $primary;
                color: #000;

                .icon-claim {
                  background-image: url('../../images/claim.svg');
                }
              }

              &:has(.icon-locked) {
                .icon-locked {
                  background-image: url('../../images/locked.svg');
                }

                &:hover {
                  color: $primary;
                }
              }
            }
          }
        }
      }

      .owl-dots {
        counter-reset: dots;
        display: flex;
        align-items: center;
        justify-content: center;

        .owl-dot {
          width: 25px;
          height: 25px;
          border-radius: 0;
          position: relative;
          opacity: 0.6;

          span {
            display: none;
          }

          &.active {
            opacity: 1;
          }

          &:before {
            counter-increment: dots;
            content: counter(dots);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            color: #fff;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
          }
        }
      }

      .owl-nav {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
        max-width: 70%;
        margin: 0 auto;

        button {
          width: 25px;
          height: 25px;
          border-radius: 0;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }
}
