@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
}

.main-project-title {
  max-width: 402px !important;
}

.slick-dots {
  bottom: -60px !important;
  > li {
    opacity: 0.5;
    &.slick-active {
      opacity: 1 !important;
    }
  }
}

.slick-prev {
  left: 25% !important;
}
.slick-next {
  right: 25% !important;
}
.slick-next,
.slick-prev {
  top: auto;
  bottom: -70px;
  z-index: 1; 
}

.toast-container {
  z-index: 99999 !important;
}

p {
  margin-bottom: 0.9375rem;
  color: #fff;
}
p:last-child {
  margin-bottom: 0;
}

body {
  color: #fff !important;
}

.modal-body {
  padding: 0px !important;
}

a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  transition: all 0.5s;
}
a:hover {
  color: #ffa51d;
}
a:visited {
  color: #fff;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/
/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/
@font-face {
  font-family: 'Satoshi-Variable';
  src:
    url('../fonts/Satoshi-Variable.woff2') format('woff2'),
    url('../fonts/Satoshi-Variable.woff') format('woff'),
    url('../fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/
@font-face {
  font-family: 'Satoshi-VariableItalic';
  src:
    url('../fonts/Satoshi-VariableItalic.woff2') format('woff2'),
    url('../fonts/Satoshi-VariableItalic.woff') format('woff'),
    url('../fonts/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Light';
  src:
    url('../fonts/Satoshi-Light.woff2') format('woff2'),
    url('../fonts/Satoshi-Light.woff') format('woff'),
    url('../fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-LightItalic';
  src:
    url('../fonts/Satoshi-LightItalic.woff2') format('woff2'),
    url('../fonts/Satoshi-LightItalic.woff') format('woff'),
    url('../fonts/Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Regular';
  src:
    url('../fonts/Satoshi-Regular.woff2') format('woff2'),
    url('../fonts/Satoshi-Regular.woff') format('woff'),
    url('../fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-Italic';
  src:
    url('../fonts/Satoshi-Italic.woff2') format('woff2'),
    url('../fonts/Satoshi-Italic.woff') format('woff'),
    url('../fonts/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Medium';
  src:
    url('../fonts/Satoshi-Medium.woff2') format('woff2'),
    url('../fonts/Satoshi-Medium.woff') format('woff'),
    url('../fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-MediumItalic';
  src:
    url('../fonts/Satoshi-MediumItalic.woff2') format('woff2'),
    url('../fonts/Satoshi-MediumItalic.woff') format('woff'),
    url('../fonts/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Bold';
  src:
    url('../fonts/Satoshi-Bold.woff2') format('woff2'),
    url('../fonts/Satoshi-Bold.woff') format('woff'),
    url('../fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-BoldItalic';
  src:
    url('../fonts/Satoshi-BoldItalic.woff2') format('woff2'),
    url('../fonts/Satoshi-BoldItalic.woff') format('woff'),
    url('../fonts/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Black';
  src:
    url('../fonts/Satoshi-Black.woff2') format('woff2'),
    url('../fonts/Satoshi-Black.woff') format('woff'),
    url('../fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-BlackItalic';
  src:
    url('../fonts/Satoshi-BlackItalic.woff2') format('woff2'),
    url('../fonts/Satoshi-BlackItalic.woff') format('woff'),
    url('../fonts/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
body {
  color: #fff;
  background-image: url(../images/body.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-family: 'Satoshi-Regular';
}
@media (min-width: 992px) and (max-width: 1119px) {
  body {
    font-size: 0.9375rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 0.875rem;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  body {
    font-size: 0.8125rem;
  }
}

h2 {
  font-size: 3.125rem;
  color: #fff;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1119px) {
  h2 {
    font-size: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 2rem;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  h2 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}

h3 {
  font-size: 1.625rem;
  color: #fff;
  font-family: 'Satoshi-Bold';
}
@media (min-width: 992px) and (max-width: 1119px) {
  h3 {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h3 {
    font-size: 1.25rem;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  h3 {
    font-size: 1.25rem;
  }
}

h4 {
  font-size: 1.375rem;
  color: #fff;
}
@media (min-width: 992px) and (max-width: 1119px) {
  h4 {
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h4 {
    font-size: 1.125rem;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  h4 {
    font-size: 1rem;
  }
}

.st-pt {
  padding-top: 5rem;
}
@media (min-width: 992px) and (max-width: 1119px) {
  .st-pt {
    padding-top: 3.4375rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .st-pt {
    padding-top: 2.8125rem;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .st-pt {
    padding-top: 2.1875rem;
  }
}

.st-pb {
  padding-bottom: 5rem;
}
@media (min-width: 992px) and (max-width: 1119px) {
  .st-pb {
    padding-bottom: 3.4375rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .st-pb {
    padding-bottom: 2.8125rem;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .st-pb {
    padding-bottom: 2.1875rem;
  }
}

@media (min-width: 992px) and (max-width: 1119px) {
  .hide-lg-tablet {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hide-sm {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .hide-xs {
    display: none;
  }
}
.modal-backdrop.show {
  -webkit-backdrop-filter: blur(8px) !important;
  backdrop-filter: blur(8px) !important;
  opacity: 1 !important;
  background: transparent !important;
}

.offcanvas {
  background: #141112;
}
.offcanvas .offcanvas-header {
  justify-content: center;
  position: relative;
}
.offcanvas .offcanvas-header h5 {
  margin: 0;
  font-size: 30px;
}
.offcanvas .offcanvas-header button {
  color: #fff;
  filter: brightness(0) invert(1);
  opacity: 1;
  border: 1px solid #fff;
  border-radius: 100%;
  background-size: 30% !important;
  justify-self: flex-end;
  position: absolute;
  right: 20px;
}
.offcanvas .offcanvas-header button:hover {
  background-color: #ffa51d !important;
  border-color: #ffa51d !important;
  filter: none;
}
.offcanvas .offcanvas-body .wallets-btn {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;
}
.offcanvas .offcanvas-body .wallets-btn .th-btn {
  border-radius: 10px;
}
.offcanvas .offcanvas-body .wallet-txt {
  text-align: center;
}
.offcanvas .offcanvas-body .wallet-txt span {
  color: #ffa51d;
}

.modal-content {
  border-radius: 20px;
  border: 2px solid #4f4d4d;
  background: #141112;
  overflow: hidden;
  border: none;
}
.modal-content.connect-pp {
  background: rgb(19, 18, 18);
}
.modal-content .modal-body {
  border: none;
}
.modal-content .btn-close {
  position: absolute;
  border: 1px solid #fff;
  opacity: 1;
  border-radius: 100%;
  filter: brightness(0) invert(1);
  background-size: 30%;
  top: 15px;
  right: 15px;
  z-index: 999;
}
.modal-content .btn-close:hover {
  filter: none;
  background-color: #ffa51d;
  border-color: #ffa51d;
}
.modal-content .popup-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  justify-content: center;
}
.modal-content .popup-content h3 {
  margin: 0;
  font-family: 'Satoshi-Regular';
}
.modal-content .popup-content h3 span {
  color: #ffa51d;
}
.modal-content .popup-content h4 {
  font-size: 16px;
  opacity: 0.5;
}
.modal-content .popup-content .th-btn {
  width: 100%;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}
.modal-content .popup-content .th-btn.light {
  color: #000;
  background: #fff;
}
.modal-content .popup-content .th-btn.light:hover {
  background: #ffa51d;
}
.modal-content .popup-content .th-btn.light:hover .icon {
  filter: none;
}

.toast {
  border-radius: 15px;
  border: 2px solid #ffa51d;
  background: var(--141112, #141112);
}
.toast.error {
  border-color: red;
}
.toast .toast-header {
  background: transparent;
  padding-bottom: 0;
}
.toast .toast-header strong {
  color: #fff;
  font-weight: normal;
  font-size: 16px;
}
.toast .toast-header .btn-close {
  filter: brightness(0) invert(1);
  opacity: 1;
  background-size: 40%;
}
.toast .toast-header .btn-close:hover {
  background-color: transparent;
}
.toast .toast-body {
  padding-top: 5 px;
}

.tippy-box {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  border-radius: 25px !important;
  background: #141112 !important;
  font-size: 14px !important;
}

.toast-container {
  z-index: 999;
}

button,
.th-btn {
  padding: 6px 24px;
  color: #fff;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  font-family: 'Satoshi-Medium';
  transition: all 0.5s;
}
button .text,
.th-btn .text {
  position: relative;
}
button:has(.icon) .icon,
.th-btn:has(.icon) .icon {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.5s;
}
button:has(.icon) .icon.icon-wallet,
.th-btn:has(.icon) .icon.icon-wallet {
  background-image: url(../images/wallet.svg);
}
button:has(.icon) .icon.icon-docs,
.th-btn:has(.icon) .icon.icon-docs {
  background-image: url(../images/docs.svg);
}
button:has(.icon) .icon.icon-claim,
.th-btn:has(.icon) .icon.icon-claim {
  background-image: url(../images/claim.svg);
}
button:hover,
.th-btn:hover {
  background-color: #ffa51d;
  border-color: #ffa51d;
  color: #000;
}
button:hover .icon,
.th-btn:hover .icon {
  filter: brightness(0);
}
button:visited,
.th-btn:visited {
  color: #fff;
}
button.btn-inverse,
.th-btn.btn-inverse {
  color: #ffa51d;
  background-color: transparent;
}
button.btn-inverse:hover,
.th-btn.btn-inverse:hover {
  color: #fff;
  background: #ffa51d;
}
@media (min-width: 768px) and (max-width: 991px) {
  button,
  .th-btn {
    padding: 12px 24px;
  }
}

input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
select,
textarea {
  border: 1px solid #6f7070;
  border-radius: 8px;
  padding: 15px 15px;
  width: 100%;
}
input[type='date']::-moz-placeholder,
input[type='email']::-moz-placeholder,
input[type='number']::-moz-placeholder,
input[type='password']::-moz-placeholder,
input[type='search']::-moz-placeholder,
input[type='tel']::-moz-placeholder,
input[type='text']::-moz-placeholder,
input[type='url']::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  color: #000;
}
input[type='date']::placeholder,
input[type='email']::placeholder,
input[type='number']::placeholder,
input[type='password']::placeholder,
input[type='search']::placeholder,
input[type='tel']::placeholder,
input[type='text']::placeholder,
input[type='url']::placeholder,
select::placeholder,
textarea::placeholder {
  color: #000;
}
input[type='date']:focus,
input[type='email']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='url']:focus,
select:focus,
textarea:focus {
  outline: none;
}

header .main {
  border-radius: 15px;
  border: 2px solid #fff;
  background: linear-gradient(180deg, #312f30 0%, #101010 100%);
}
header .main .level-block {
  gap: 5px;
}
header .main .level-block .badge {
  width: 80px;
}
header .main .level-block .badge img {
  width: 100%;
}
header .main .level-block .name {
  font-size: 50px;
  line-height: 1;
  font-family: 'Satoshi-Bold';
}
header .main .level-block .name span {
  color: #ffa51d;
}

section {
  padding: 2.5rem 0;
}
section.dashboard .panel {
  min-height: 637px;
  height: 100%;
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  background: #141112;
  padding: 25px;
  position: relative;
}
section.dashboard .panel .xp-overview {
  position: absolute;
  top: 12px;
  right: 13px;
}
section.dashboard .panel .xp-overview a {
  color: #ffa51d;
}
section.dashboard .panel .xp-overview a svg {
  fill: #fff;
  transition: all 0.5s;
}
section.dashboard .panel .xp-overview a:hover svg {
  opacity: 0.9;
  fill: #ffa51d;
}
section.dashboard .panel .main-counter {
  width: 310px;
  height: 310px;
  margin: 0 auto;
  border: 3px solid #fff;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  text-align: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 332px;
  border: 2px solid var(--FFA51D, #ffa51d);
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 165, 29, 0.15) 0%,
    rgba(20, 17, 18, 0.15) 100%
  );
  box-shadow: 0px 4px 17.3px 0px #ffa51d;
}
section.dashboard .panel .main-counter h3 {
  font-size: 70px;
  font-family: 'Satoshi-Black';
  margin: 0;
}
section.dashboard .panel .main-counter p {
  color: rgba(255, 255, 255, 0.5);
}
section.dashboard .panel .counters {
  margin: 10px 0;
}
section.dashboard .panel .counters .counter {
  border: 1px solid rgba(255, 255, 255, 0.4);
  margin: 10px auto;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
section.dashboard .panel .counters .counter h4 {
  margin: 0;
  font-size: 18px;
  color: #ffa51d;
  font-family: 'Satoshi-Medium';
}
section.dashboard .panel .counters .counter p {
  font-size: 12px;
}
section.dashboard .panel .maturation {
  margin-top: 20px;
}
section.dashboard .panel .maturation h4 {
  margin: 0;
}
section.dashboard .panel .maturation p {
  opacity: 0.5;
}
section.dashboard .panel .maturation .countdown {
  font-size: 22px;
  color: #ffa51d;
}
section.dashboard .panel .maturation .countdown span {
  color: #fff;
}
section.dashboard .panel .sub-level h3 {
  margin: 0;
}
section.dashboard .panel .sub-level h3 span {
  color: #ffa51d;
}
section.dashboard .panel .owl-stage-outer {
  margin-bottom: 25px;
}
section.dashboard .panel .cards-block .card {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
}
section.dashboard .panel .cards-block .card.locked:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.5;
  z-index: 99;
}
section.dashboard .panel .cards-block .card .dis {
  padding: 10px;
}
section.dashboard .panel .cards-block .card .dis h4 {
  font-size: 15px;
  margin: 0;
}
section.dashboard .panel .cards-block .card .dis h4 span {
  color: #ffa51d;
}
section.dashboard .panel .cards-block .card .dis p {
  font-size: 12px;
  margin-top: 5px;
}
section.dashboard .panel .cards-block .card .action button {
  width: 100%;
  border-radius: 0;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 15px;
}
section.dashboard .panel .cards-block .card .action button .icon {
  width: 17px;
  height: 17px;
}
section.dashboard .panel .cards-block .card .action button:hover {
  background: transparent;
}
section.dashboard .panel .cards-block .card .action button:hover .icon {
  filter: none;
}
section.dashboard .panel .cards-block .card .action button:has(.icon-claim) {
  background: #fff;
  color: #000;
}
section.dashboard .panel .cards-block .card .action button:has(.icon-claim) .icon-claim {
  background-image: url(../images/claim.svg);
}
section.dashboard .panel .cards-block .card .action button:has(.icon-locked) .icon-locked {
  background-image: url(../images/locked.svg);
}
section.dashboard .panel .cards-block .card .action button:has(.icon-locked):hover {
  color: #fff;
}
section.dashboard .panel .owl-dots {
  counter-reset: dots;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.dashboard .panel .owl-dots .owl-dot {
  width: 25px;
  height: 25px;
  border-radius: 0;
  position: relative;
  opacity: 0.6;
}
section.dashboard .panel .owl-dots .owl-dot span {
  display: none;
}
section.dashboard .panel .owl-dots .owl-dot.active {
  opacity: 1;
}
section.dashboard .panel .owl-dots .owl-dot:before {
  counter-increment: dots;
  content: counter(dots);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
section.dashboard .panel .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  max-width: 70%;
  margin: 0 auto;
}
section.dashboard .panel .owl-nav button {
  width: 25px;
  height: 25px;
  border-radius: 0;
  background-position: center;
  background-size: contain;
} /*# sourceMappingURL=main.css.map */

.offcanvas {
  color: #fff !important; /* Replace your-desired-color with the desired color value */
}

.maturation {
  display: flex;
  flex-direction: column; /* Organise les enfants verticalement */
  align-items: center; /* Centre horizontalement */
  justify-content: center; /* Centre verticalement si le conteneur a une hauteur spécifique */
  text-align: center; /* Assure que le texte à l'intérieur des éléments enfants est également centré */
  height: 100%; /* Ajustez selon le besoin, peut être spécifique (px, em, etc.) */
}

.cards-block .card .img-block {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 56.25%;
}

.cards-block .card .img-block img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

