header {
  .main {
    border-radius: 15px;
    border: 2px solid #fff;
    background: linear-gradient(180deg, #312f30 0%, #101010 100%);

    .level-block {
      gap: 5px;
      .badge {
        width: 80px;
        img {
          width: 100%;
        }
      }

      .name {
        font-size: 50px;
        line-height: 1;

        font-family: 'Satoshi-Bold';
        span {
          color: $secondary;
        }
      }
    }
  }
}
