.st-pt {
  padding-top: rem(80);

  @include respond-to(md) {
    padding-top: rem(55);
  }

  @include respond-to(sm) {
    padding-top: rem(45);
  }

  @include respond-to(xs) {
    padding-top: rem(35);
  }
}

.st-pb {
  padding-bottom: rem(80);

  @include respond-to(md) {
    padding-bottom: rem(55);
  }

  @include respond-to(sm) {
    padding-bottom: rem(45);
  }

  @include respond-to(xs) {
    padding-bottom: rem(35);
  }
}

@include respond-to(md) {
  .hide-lg-tablet {
    display: none;
  }
}

@include respond-to(sm) {
  .hide-sm {
    display: none;
  }
}

@include respond-to(xs) {
  .hide-xs {
    display: none;
  }
}

.modal-backdrop.show {
  backdrop-filter: blur(8px) !important;
  opacity: 1 !important;
  background: transparent !important;
}

.offcanvas {
  background: #141112;

  .offcanvas-header {
    justify-content: center;
    position: relative;

    h5 {
      margin: 0;
      font-size: 30px;
    }

    button {
      color: #fff;
      filter: brightness(0) invert(1);
      opacity: 1;
      border: 1px solid #fff;
      border-radius: 100%;
      background-size: 30% !important;
      justify-self: flex-end;
      position: absolute;
      right: 20px;

      &:hover {
        background-color: $secondary !important;
        border-color: $secondary !important;
        filter: none;
      }
    }
  }

  .offcanvas-body {
    overflow: hidden;
    .wallets-btn {
      display: flex;
      gap: 15px;
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 20px;

      .th-btn {
        border-radius: 10px;
      }
    }

    .wallet-txt {
      text-align: center;

      span {
        color: $secondary;
      }
    }
  }
}

.modal-content {
  border-radius: 20px;
  border: 2px solid #4f4d4d;
  background: #141112;
  overflow: hidden;
  border: none;

  &.connect-pp {
    background: rgb(19, 18, 18);
  }

  .modal-body {
    border: none;
  }

  .btn-close {
    position: absolute;
    border: 1px solid #fff;
    opacity: 1;
    border-radius: 100%;
    filter: brightness(0) invert(1);
    background-size: 30%;
    top: 15px;
    right: 15px;
    z-index: 999;
    &:hover {
      filter: none;
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  .popup-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    justify-content: center;

    h3 {
      margin: 0;
      font-family: 'Satoshi-Regular';

      span {
        color: $secondary;
      }
    }

    h4 {
      font-size: 16px;
      opacity: 0.5;
    }

    .th-btn {
      width: 100%;
      padding: 10px;
      text-align: center;
      text-transform: uppercase;

      &.light {
        color: #000;
        background: $primary;

        &:hover {
          background: $secondary;

          .icon {
            filter: none;
          }
        }
      }
    }
  }
}

.toast {
  border-radius: 15px;
  border: 2px solid $secondary;
  background: var(--141112, #141112);

  &.error {
    border-color: red;
  }

  .toast-header {
    background: transparent;
    padding-bottom: 0;

    strong {
      color: $primary;
      font-weight: normal;
      font-size: 16px;
    }

    .btn-close {
      filter: brightness(0) invert(1);
      opacity: 1;
      background-size: 40%;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .toast-body {
    padding-top: 5 px;
  }
}

.tippy-box {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  border-radius: 25px !important;
  background: #141112 !important;
  font-size: 14px !important;
}

.toast-container {
  z-index: 999;
}
