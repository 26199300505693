button,
.th-btn {
  padding: 6px 24px;
  color: #fff;
  background: transparent;
  border: 1px solid rgb(255, 255, 255 / 50%);
  border-radius: 20px;
  font-family: 'Satoshi-Medium';
  transition: all 0.5s;

  .text {
    position: relative;
  }

  &:has(.icon) {
    .icon {
      position: relative;
      top: 3px;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 0.5s;

      &.icon-wallet {
        background-image: url('../../images/wallet.svg');
      }

      &.icon-docs {
        background-image: url('../../images/docs.svg');
      }

      &.icon-claim {
        background-image: url('../../images/claim.svg');
      }
    }
  }

  &:hover {
    background-color: $secondary;
    border-color: $secondary;
    color: #000;

    .icon {
      filter: brightness(0);
    }
  }

  &:visited {
    color: #fff;
  }

  &.btn-inverse {
    color: $secondary;
    background-color: transparent;

    &:hover {
      color: #fff;
      background: $secondary;
    }
  }

  @include respond-to(sm) {
    padding: 12px 24px;
  }
}
