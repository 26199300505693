body {
  color: $primary;
  background-image: url('../../images/body.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-family: 'Satoshi-Regular';

  @include respond-to(md) {
    font-size: rem(15);
  }

  @include respond-to(sm) {
    font-size: rem(14);
  }

  @include respond-to(xs) {
    font-size: rem(13);
  }
}

h2 {
  font-size: rem(50);
  color: $primary;
  margin-bottom: 0;

  @include respond-to(md) {
    font-size: rem(40);
  }

  @include respond-to(sm) {
    font-size: rem(32);
  }

  @include respond-to(xs) {
    font-size: rem(24);
    margin-bottom: 0;
  }
}

h3 {
  font-size: rem(26);
  color: $primary;
  font-family: 'Satoshi-Bold';

  @include respond-to(md) {
    font-size: rem(24);
  }

  @include respond-to(sm) {
    font-size: rem(20);
  }

  @include respond-to(xs) {
    font-size: rem(20);
  }
}

h4 {
  font-size: rem(22);
  color: $primary;

  @include respond-to(md) {
    font-size: rem(20);
  }

  @include respond-to(sm) {
    font-size: rem(18);
  }

  @include respond-to(xs) {
    font-size: rem(16);
  }
}
